import React, { useEffect, useState } from "react";
import {
  MakeApiCallAsync,
} from "../../../helpers/ApiHelpers";
import Config from "../../../helpers/Config";
import { useDispatch } from "react-redux";
import rootAction from "../../../stateManagment/actions/rootAction";
import { LOADER_DURATION } from "../../../helpers/Constants";
import { Link, useNavigate } from "react-router-dom";
import {
  getLanguageCodeFromSession,
  GetLocalizationControlsJsonDataForScreen,
  replaceLoclizationLabel,
} from "../../../helpers/CommonHelper";
import GlobalEnums from "../../../helpers/GlobalEnums";
import { replaceWhiteSpacesWithDashSymbolInUrl } from "../../../helpers/ConversionHelper";

const PopularProducts = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [adminPanelBaseURL, setBaseUrl] = useState(Config["ADMIN_BASE_URL"]);
  const [ProductsList, setProductsList] = useState([]);
  const [LocalizationLabelsArray, setLocalizationLabelsArray] = useState([]);
  const [PaginationInfo, setPaginationInfo] = useState({
    PageNo: 1,
    PageSize: 20,
    TotalRecords: 0,
  });

  useEffect(() => {
    // declare the data fetching function
    const getNewProductsList = async () => {
      const headers = {
        // customerid: userData?.UserID,
        // customeremail: userData.EmailAddress,
        Accept: "application/json",
        "Content-Type": "application/json",
      };

      const param = {
        requestParameters: {
          PageNo: PaginationInfo.PageNo,
          PageSize: PaginationInfo.PageSize,
          recordValueJson: "[]",
        },
      };

      const response = await MakeApiCallAsync(
        Config.END_POINT_NAMES["GET_POPULAR_PRODUCTS_LIST"],
        null,
        param,
        headers,
        "POST",
        true
      );
      if (response != null && response.data != null) {
        await setProductsList(JSON.parse(response.data.data));
        console.log(JSON.parse(response.data.data));
      }

      //-- Get website localization data
      let arryRespLocalization = await GetLocalizationControlsJsonDataForScreen(
        GlobalEnums.Entities["PopularProducts"],
        null
      );
      if (
        arryRespLocalization != null &&
        arryRespLocalization != undefined &&
        arryRespLocalization.length > 0
      ) {
        await setLocalizationLabelsArray(arryRespLocalization);
      }
    };

    //--start loader
    dispatch(rootAction.commonAction.setLoading(true));

    // call the function
    getNewProductsList().catch(console.error);

    //--stop loader
    setTimeout(() => {
      dispatch(rootAction.commonAction.setLoading(false));
    }, LOADER_DURATION);
  }, []);


  return (
    <>
      <section className="products-area pt-60 pb-30">
        <div className="container">
          <div className="section-title">
            <div className="row">
              <div
                style={{ display: "flex", flexDirection: "column" }}
                className="col-12"
              >
                <h2 style={{ float: "left" }}>
                  <span id="lbl_popprd_title">
                    {LocalizationLabelsArray.length > 0
                      ? replaceLoclizationLabel(
                          LocalizationLabelsArray,
                          "Mostviewed Products",
                          "lbl_popprd_title"
                        )
                      : "Mostviewed Products"}
                  </span>{" "}
                </h2>
                <p>
                  Mirum est notare quam littera gothica, quam nunc putamus parum
                  claram anteposuerit litterarum formas.
                </p>
              </div>
              {/* <div className="col-6">
                  <div className="products-view-all">
                    <Link
                      to={`/${getLanguageCodeFromSession()}/all-products/0/popular-products`}
                      id="lbl_popprd_viewall"
                    >
                      {LocalizationLabelsArray.length > 0
                        ? replaceLoclizationLabel(
                            LocalizationLabelsArray,
                            "View All",
                            "lbl_popprd_viewall"
                          )
                        : "View All"}
                    </Link>
                  </div>
                </div> */}
            </div>
          </div>
          <div className="product-suggestions-banner">
            {ProductsList.length > 0 ? (
              ProductsList.slice(0, 3).map((product, index) => {
                let urlViewDetailImage = `/${getLanguageCodeFromSession()}/product-detail/${
                  product.ProductId
                }/${
                  replaceWhiteSpacesWithDashSymbolInUrl(product.CategoryName) ??
                  "shop"
                }/${replaceWhiteSpacesWithDashSymbolInUrl(
                  product.ProductName
                )}`;
                return (
                  <div key={index} className="popular-product-box">
                    <img
                      src={
                        adminPanelBaseURL +
                        product.ProductImagesJson[0].AttachmentURL
                      }
                    />
                    <span>{product.CategoryName}</span>
                    <p>{product.ProductName}</p>
                    <button onClick={() => navigate(urlViewDetailImage)}>
                      READ MORE
                    </button>
                  </div>
                );
               })
            ) : (
                <div className="no-products-found">
                  <h3>No Products Found</h3>
              </div>
            )}
            {/* <div className="popular-product-box">
              <img src={popPro1} />
              <span>Face Cream Natural Cream | December 24, 2021</span>
              <p>Glow Like Natural Healthy-Looking Cream</p>
              <button>READ MORE</button>
            </div>
            <div className="popular-product-box">
              <img src={popPro2} />
              <span>Face Cream Natural Cream | December 24, 2021</span>
              <p>The Ideal Diet Oil For Your Healthy Skin</p>
              <button>READ MORE</button>
            </div>
            <div className="popular-product-box">
              <img src={popPro3} />
              <span>Face Cream Natural Cream | December 24, 2021</span>
              <p>Get The Perfectly Hydrated Skin Cream</p>
              <button>READ MORE</button>
            </div> */}
          </div>
        </div>
      </section>
    </>
  );
};

export default PopularProducts;
