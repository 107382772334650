import React from "react";
import "swiper/css";
import { Swiper, SwiperSlide } from "swiper/react";
import logo1 from "../../resources/themeContent/images/logo-1.png"
import logo2 from "../../resources/themeContent/images/logo-2.png"
import logo3 from "../../resources/themeContent/images/logo-3.png"
import logo4 from "../../resources/themeContent/images/logo-4.png"
import logo5 from "../../resources/themeContent/images/logo-5.png"

const BrandShowCaseSlider = () => {
  return (
    <div className="container brand-showcase-slider">
      <Swiper
        spaceBetween={50}
        slidesPerView={4}
      >
        <SwiperSlide>
            <img src={logo1} alt="slide" />
        </SwiperSlide>
        <SwiperSlide>
            <img src={logo2} alt="slide" />
        </SwiperSlide>
        <SwiperSlide>
            <img src={logo3} alt="slide" />
        </SwiperSlide>
        <SwiperSlide>
            <img src={logo4} alt="slide" />
        </SwiperSlide>
        <SwiperSlide>
            <img src={logo5} alt="slide" />
        </SwiperSlide>
      </Swiper>
    </div>
  );
};

export default BrandShowCaseSlider;
