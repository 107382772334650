import React from 'react'
import image1 from "../../resources/themeContent/images/insta-image-1.png"
import image2 from "../../resources/themeContent/images/insta-image-2.png"
import image3 from "../../resources/themeContent/images/insta-image-3.png"
import image4 from "../../resources/themeContent/images/insta-image-4.png"
import image5 from "../../resources/themeContent/images/insta-image-5.png"

const InstagramBanner = () => {
  return (
      <div className='instagram-banner-container' >
          <img src={image1} alt='insta-banner' />
          <img src={image2} alt='insta-banner' />
          <img src={image3} alt='insta-banner' />
          <img src={image4} alt='insta-banner' />
          <img src={image5} alt='insta-banner' />
          <div className='insta-banner-text' >
              <p>#instagram</p>
          </div>
      </div>
  )
}

export default InstagramBanner