import React, { useState, useEffect } from "react";
import Link from "next/link";
import VisibilitySensor from "react-visibility-sensor";
import dynamic from "next/dynamic";
import { MakeApiCallAsync } from "../../../helpers/ApiHelpers";
import Config from "../../../helpers/Config";

const OwlCarousel = dynamic(import("react-owl-carousel3"));

const options = {
  items: 1,
  loop: true,
  nav: true,
  dots: false,
  animateOut: "slideOutLeft",
  animateIn: "slideInRight",
  smartSpeed: 750,
  autoplay: true,
  autoplayHoverPause: true,
  navText: [
    "<i class='fas fa-arrow-left custom-nav-arrow left'></i>",
    "<i class='fas fa-arrow-right custom-nav-arrow right'></i>",
  ],
};

const BannerSlider = (props) => {
  const [display, setDisplay] = useState(false);
  const [bannerList, setBannerList] = useState([]);
  const adminPanelBaseURL = Config["ADMIN_BASE_URL"];

  useEffect(() => {
    const fetchData = async () => {
      const headers = {
        Accept: "application/json",
        "Content-Type": "application/json",
      };

      const param = {
        requestParameters: {
          recordValueJson: "[]",
        },
      };

      const response = await MakeApiCallAsync(
        Config.END_POINT_NAMES["GET_HOME_SCREEN_BANNER"],
        null,
        param,
        headers,
        "POST",
        true
      );
      console.log(response);
      if (response != null && response.data != null) {
        setBannerList(JSON.parse(response.data.data));
      }

      setTimeout(() => {
        setDisplay(true);
      }, 500);
    };

    fetchData();
  }, []);

  return (
    <>
      {display && (
        <OwlCarousel
          className="home-slides-three owl-carousel owl-theme"
          {...options}
        >
          {bannerList
            .filter((x) => x.ThemeTypeID === 1)
            .map((item) => (
              <div
                key={item.BannerId}
                className="main-banner"
                style={{
                  backgroundImage: `url(${
                    adminPanelBaseURL + item.BannerImgUrl
                  })`,
                }}
              >
                <div className="d-table">
                  <div className="d-table-cell">
                    <div className="container">
                      <VisibilitySensor>
                        {({ isVisible }) => (
                          <div className="main-banner-content white-color">
                            <span
                              className={`${
                                isVisible
                                  ? "animated fadeInUp opacityOne"
                                  : "opacityZero"
                              } sacramento`}
                            >
                              {item.TopTitle}
                            </span>
                            <h1
                              className={`${
                                isVisible
                                  ? "animated fadeInUp opacityOne"
                                  : "opacityZero"
                              } mulish`}
                            >
                              {item.MainTitle}
                            </h1>
                            <p
                              className={
                                isVisible
                                  ? "animated fadeInUp opacityOne"
                                  : "opacityZero"
                              }
                            >
                              {item.BottomTitle}
                            </p>

                            {item.LeftButtonText &&
                              item.LeftButtonText.length > 0 && (
                                <Link href="#">
                                  <a
                                    className={`btn btn-primary ${
                                      isVisible
                                        ? "animated fadeInUp opacityOne"
                                        : "opacityZero"
                                    }`}
                                    onClick={(e) => {
                                      e.preventDefault();
                                      props.handleBannerButtonClickUrl(
                                        item.LeftButtonUrl
                                      );
                                    }}
                                  >
                                    {item.LeftButtonText}
                                  </a>
                                </Link>
                              )}

                            {item.RightButtonText &&
                              item.RightButtonText.length > 0 && (
                                <Link href="#">
                                  <a
                                    className={`btn btn-light ${
                                      isVisible
                                        ? "animated fadeInUp opacityOne"
                                        : "opacityZero"
                                    }`}
                                    onClick={(e) => {
                                      e.preventDefault();
                                      props.handleBannerButtonClickUrl(
                                        item.RightButtonUrl
                                      );
                                    }}
                                  >
                                    {item.RightButtonText}
                                  </a>
                                </Link>
                              )}
                          </div>
                        )}
                      </VisibilitySensor>
                    </div>
                  </div>
                </div>
              </div>
            ))}
        </OwlCarousel>
      )}
      <style jsx>{`
        .custom-nav-arrow {
          position: absolute;
          top: 50%;
          transform: translateY(-50%);
          font-size: 1rem;
          z-index: 2;
          cursor: pointer;
          background:#afcccc;
          color:#000;
          padding:10px;
        }
        .custom-nav-arrow.left {
          left: 15px;
        }
        .custom-nav-arrow.right {
          right: 15px;
        }
      `}</style>
    </>
  );
};

export default BannerSlider;
